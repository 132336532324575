import { useState, useEffect, useRef } from "react";
import Template from "../template/Template.jsx";
import Countdown from "./Countdown.jsx";
import { useIDO } from "./useCustomIdoHook.jsx";

const Ido = () => {
  const ref = useRef();
  const { saleData, userData, getUsdt } = useIDO();
  const [IdoData, setIdoData] = useState({});
  const [isStarted, setIsStarted] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [timerDuration, setTimerDuration] = useState(0);
  const [error, setError] = useState(null); // State to handle errors

  const fetchSaleData = async () => {
    try {
      await saleData.refetch();
      if (saleData.data !== undefined) {
        if (Date.now() / 1000 < saleData.data[0]["startdate"]) {
          setIsStarted(false);
          setTimerDuration(saleData.data[0]["startdate"] * 1000);
        } else {
          setIsStarted(true);
          setTimerDuration(saleData.data[0]["enddate"] * 1000);
          if (Date.now() / 1000 > saleData.data[0]["enddate"]) {
            setIsEnded(true);
            setTimerDuration(0);
          }
        }
      }
      saleData.data && setIdoData(saleData.data[0]);
      console.log("Sale Data Fetched: ", saleData.data); // Debug log
    } catch (err) {
      setError('Failed to fetch sale data.'); // Set error message
      console.error("Error fetching sale data: ", err);
    }
  };

  const fetchUserData = async () => {
    try {
      await userData.refetch();
      console.log("User Data Fetched: ", userData.data); // Debug log
    } catch (err) {
      console.error("Error fetching user data: ", err);
    }
  };

  useEffect(() => {
    fetchSaleData();
    fetchUserData();
  }, [saleData.data, userData.data]);

  const tokenDetails = [
    { name: "Total Supply:", value: IdoData ? IdoData["totalTokens"] : 0 },
    {
      name: "Sale Allocation:",
      value: IdoData ? IdoData["remainingToken"] + " XRR(Available)" : 0,
    },
    {
      name: "Token Price:",
      value: IdoData ? "$" + IdoData["tokenPrice"] + " (Round 1)" : 0,
    },
  ];

  const handleBuy = async () => {
    try {
      const amount = ref.current.value; // This is the amount in XRR entered by the user
      if (amount < 800) {
        setError('Minimum purchase amount is 800 XRR');
        return;
      }
      const usdtAmount = amount / 80; // Assuming 1 USDT = 80 XRR
      await getUsdt(usdtAmount * 1e18);
      setError(null); // Clear error on success
      fetchSaleData(); // Refetch sale data to update the remaining tokens
      fetchUserData(); // Refetch user data to update the purchased tokens
    } catch (err) {
      setError('Transaction failed. Please try again.'); // Set error message
      console.error("Transaction error: ", err);
    }
  };

  const handleCountdownEnd = () => {
    window.location.reload();
  };

  const date = timerDuration;
  const IdoComponent = () => {
    const stylingClass =
      "border rounded-3xl p-2.5 bg-[#D9D9D9] border-black indent-2 w-full flex justify-center items-center relative text-sm";
    return (
      <div className="flex flex-col gap-5 mt-5 mx-8">
        <h2 className="font-medium text-4xl text-center">
          {isStarted ? "Token Sale Ends in" : "Token Sale Starts in"}
        </h2>
        <Countdown date={date} onEnd={handleCountdownEnd} />
        {error && <div className="text-red-500">{error}</div>} {/* Display error */}
        <div>
          <h2 className="font-medium text-2xl">Token details</h2>
          {tokenDetails.map((detail, index) => {
            return (
              <div className="my-1" key={index}>
                <tr className="border-spacing-1">
                  <td className="text-sm font-medium w-[150px]">
                    {detail.name}
                  </td>
                  <td className="text-sm font-medium">{detail.value}</td>
                </tr>
              </div>
            );
          })}
        </div>
        <div>
          <input
            type="number"
            placeholder="Enter amount in XRR"
            className={stylingClass}
            ref={ref}
            disabled={isEnded || !isStarted}
          />
        </div>
        <button
          className={`${stylingClass} font-bold border-0 !bg-[#A9BCFF]`}
          disabled={isEnded || !isStarted}
          onClick={handleBuy}
        >
          Buy XRR
        </button>
        <div className="text-sm text-right font-medium">
          Token Purchased:
          {userData.isSuccess && userData.data.length !== 0
            ? userData.data[0].xrrToken
            : 0}
        </div>
        {(IdoData["remainingToken"] <= 0 || isEnded) && (
          <span>
            <img
              src="../../img/soldout-banner.png"
              alt=""
              className="absolute top-[30%] -rotate-[16deg] h-[200px] w-[610px]"
            />
          </span>
        )}
      </div>
    );
  };

  return <Template Component={<IdoComponent />} />;
};

export default Ido;
