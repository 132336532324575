// Use this for production (deployed backend)
const PRODUCTION_API_URL = "https://salebackend.rapidrush.xyz/api/v1/";

const BASE_API_URL = process.env.NODE_ENV === 'development' ? 'https://localhost:8000/api/v1/' : PRODUCTION_API_URL;

const useApi = () => {
  const triggerApi = async (
    api_url = BASE_API_URL,
    endpoint,
    type,
    data = {}
  ) => {
    const body = {
      method: type,
      headers: {
        "content-type": "application/json",
      },
    };

    if (data && type !== "GET") {
      body["body"] = JSON.stringify(data);
    }

    const response = await fetch(api_url + endpoint, body);

    if (!response.ok) {
      throw new Error(await response.text());
    }
    return response.json();
  };

  const registerUser = (data) => {
    return triggerApi(BASE_API_URL, "user/register/", "POST", data);
  };

  const checkUser = () => {
    const walletAddress = localStorage.getItem("walletAddress");
    return triggerApi(BASE_API_URL, `user/get/${walletAddress}/`, "GET", "");
  };

  const updateUserToken = (data) => {
    const walletAddress = localStorage.getItem("walletAddress");
    return triggerApi(
      BASE_API_URL,
      `user/update-token/${walletAddress}/`,
      "PUT",
      data
    );
  };

  const createTransaction = (data) => {
    return triggerApi(BASE_API_URL, "sale/create-transaction/", "POST", data);
  };

  const listTransaction = () => {
    const walletAddress = localStorage.getItem("walletAddress");
    return triggerApi(
      BASE_API_URL,
      `sale/list-transaction/${walletAddress}/`,
      "GET",
      ""
    );
  };

  const IdoPageData = () => {
    return triggerApi(BASE_API_URL, `sale/status/`, "GET", "");
  };

  return {
    registerUser,
    checkUser,
    createTransaction,
    listTransaction,
    IdoPageData,
    updateUserToken,
  };
};

export default useApi;
