import Template from "../template/Template.jsx";

import { useLogin } from "./useLogin";
import WalletConnect from "./subcomponents/walletConnect.jsx";
import UserData from "./subcomponents/userData.jsx";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [userInfo, setUserInfo] = useState({
    fullname: "",
    email: "",
    walletAddress: "",
  });

  const { connectWallet, refetch, mutate, userData, data } = useLogin();

  // useEffect(() => {
  //   if (data != null) {
  //     data.length == 0 ? setPage(1) : setPage(2);
  //   }
  // }, [data]);

  useEffect(() => {
    const getData = async () => {
      await refetch();
      if (data && localStorage.getItem("walletAddress") != null) {
        if (data.length == 1) {
          navigate("/IDO");
        } else {
          setPage(1);
        }
      }
    };
    getData();
  });

  const handleWalletConnect = async () => {
    await connectWallet();
    setUserInfo((prev) => ({
      ...prev,
      walletAddress: localStorage.getItem("walletAddress"),
    }));
  };

  const CurrentPage = () => {
    if (page == 0) return <WalletConnect connect={handleWalletConnect} />;
    else if (page == 1)
      return (
        <UserData
          setUserInfo={setUserInfo}
          userInfo={userInfo}
          handleLogin={() => mutate(userInfo)}
        />
      );
    else navigate("/IDO");
  };

  return <Template Component={CurrentPage()} />;
};

export default Login;
