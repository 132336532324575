import { useEffect, useState } from "react";

const Countdown = ({ date, onEnd }) => {
  const [countDown, setCountDown] = useState(null);

  useEffect(() => {
    if (date) {
      const countDownDate = new Date(date).getTime();
      const interval = setInterval(() => {
        const timeLeft = countDownDate - new Date().getTime();
        setCountDown(timeLeft);

        if (timeLeft <= 0) {
          clearInterval(interval);
          onEnd();
        }
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [date, onEnd]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  const days = countDown !== null ? Math.floor(countDown / (1000 * 60 * 60 * 24)) : '-';
  const hours = countDown !== null ? Math.floor((countDown / (1000 * 60 * 60)) % 24) : '-';
  const minutes = countDown !== null ? Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)) : '-';
  const seconds = countDown !== null ? Math.floor((countDown % (1000 * 60)) / 1000) : '-';

  const countDownData = [
    { name: "days", value: days },
    { name: "hours", value: hours },
    { name: "minutes", value: minutes },
    { name: "seconds", value: seconds },
  ];

  return (
    <div className="flex justify-between">
      {countDownData.map((item, index) => (
        <div key={index} className="flex flex-col">
          <div className="h-16 w-16 border-2 rounded-md border-black bg-[#D9D9D9] flex items-center justify-center text-3xl font-bold">
            {item.value}
          </div>
          <div className="text-[#6B86E2] capitalize text-center">
            {item.name}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Countdown;
