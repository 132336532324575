import { useEffect, useState } from "react";
import { Web3 } from "web3";
import { useQuery, useMutation } from "@tanstack/react-query";
import useApi from "../useApi";
import { useNavigate } from "react-router-dom";

const useLogin = () => {
  const navigate = useNavigate();
  const { checkUser, registerUser } = useApi();
  const [userData, setUserData] = useState(null);

  const { data, refetch } = useQuery({
    queryKey: ["user"],
    queryFn: checkUser,
    enabled: false,
  });

  const { mutate } = useMutation({
    mutationKey: ["create"],
    mutationFn: registerUser,
    onSuccess: (data) => {
      console.log(data);
      navigate("/IDO");
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const web3 = new Web3(window.ethereum);
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const accounts = await web3.eth.getAccounts();
        localStorage.setItem("walletAddress", accounts[0]);
      } catch (error) {
        console.error("connection denied", error);
      }
    } else {
      console.error("MetaMask is not installed");
    }
  };

  return { connectWallet, refetch, mutate, userData, data };
};

export { useLogin };
