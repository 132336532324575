import { MoveRight } from "lucide-react";

const UserData = ({ userInfo, setUserInfo, handleLogin }) => {
  const stylingClass =
    "border rounded-3xl p-2.5 bg-[#EEEEEE] border-black indent-2 w-full flex justify-center items-center relative text-sm";

  return (
    <div className="flex flex-col py-12 px-5 gap-4">
      <div className="flex flex-col">
        <label htmlFor="" className="font-bold">
          Full Name
        </label>
        <input
          type="text"
          placeholder="Full Name"
          className={stylingClass}
          value={userInfo["fullname"]}
          onChange={(e) =>
            setUserInfo((prev) => ({ ...prev, fullname: e.target.value }))
          }
        />
      </div>

      <div className="flex flex-col">
        <label htmlFor="" className="font-bold">
          Email
        </label>
        <input
          type="email"
          placeholder="email@email.com"
          className={stylingClass}
          value={userInfo["email"]}
          onChange={(e) => {
            setUserInfo((prev) => ({ ...prev, email: e.target.value }));
          }}
        />
      </div>

      <div>
        <button
          className={`${stylingClass} !bg-[#A9BCFF] border-0 font-bold text-base`}
          onClick={handleLogin}
        >
          <p className="flex justify-center gap-2">
            Login <MoveRight />
          </p>
        </button>
      </div>
    </div>
  );
};

export default UserData;
