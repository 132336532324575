const WalletConnect = ({ connect }) => {
  const stylingClass =
    "border rounded-3xl p-2.5 bg-[#EEEEEE] border-black indent-2 w-full flex justify-center items-center relative text-sm";

  return (
    <div className="flex flex-col py-12 px-5 gap-4 my-20">
      <div className="flex sm:gap-5 gap-2 items-end">
        <div className="w-1/2">
          <p className="text-xs my-2">Connect Wallet</p>
          <button className={`${stylingClass} p-[22px]`} onClick={() => connect('metamask')}>
            <div className="absolute inset-0 flex items-center justify-center">
              <img
                src="../../img/metamask-logo.png"
                alt="metamask"
                className="h-10 mr-2"
              />
              <p>MetaMask</p>
            </div>
          </button>
        </div>

        <div className="w-1/2">
          <button className={`${stylingClass} p-[22px] indent-0 sm:indent-2`} onClick={() => connect('walletconnect')}>
            <div className="absolute inset-0 flex items-center justify-center">
              <img
                src="../../img/walletconnect-logo.png"
                alt="walletconnect"
                className="h-8 w-10 mr-2"
              />
              <p>WConnect</p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default WalletConnect;
