import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import useApi from "../useApi";
import address from "../../asset/address.json";
import abi from "../../asset/abi.json";
import Web3 from "web3";

const useIDO = () => {
  const queryClient = useQueryClient();
  const { IdoPageData, checkUser, updateUserToken, createTransaction } = useApi();

  const web3 = new Web3(window.ethereum);

  const saleContract = new web3.eth.Contract(
    abi.saleContractAbi,
    address.saleContractAddress
  );

  const xrrContract = new web3.eth.Contract(
    abi.xrrAbi,
    address.xrrTokenAddress
  );

  const usdtContract = new web3.eth.Contract(abi.usdtAbi, address.usdtAddress);

  const updateToken = useMutation({
    mutationKey: ["tokenUpdate"],
    mutationFn: updateUserToken,
    onSuccess: (data) => {
      console.log(data);
      queryClient.invalidateQueries(["sale"]);
      queryClient.invalidateQueries(["user"]);
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const create_transaction = useMutation({
    mutationKey: ["createTransaction"],
    mutationFn: createTransaction,
    onSuccess: (data) => {
      console.log(data);
      queryClient.invalidateQueries(["user"]); // Invalidate user query on success
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const getUsdt = async (amount) => {
    const wallet = localStorage.getItem("walletAddress");
    const simplifiedToken = (80 * amount) / 1e18;
    try {
      await usdtContract.methods
        .transfer(address["ownerAddress"], amount)
        .send({ from: wallet, gas: 60000 })
        .on("transactionHash", function (hash) {
          setTimeout(() => {
            create_transaction.mutate({
              walletAddress: wallet,
              transaction: hash,
              amount: simplifiedToken,
            });
          }, 60 * 1000);
        })
        .on("error", async function () {
          console.log("Error");
        });
    } catch (error) {
      console.log(error);
    }
  };

  const saleData = useQuery({
    queryKey: ["sale"],
    queryFn: IdoPageData,
  });

  const userData = useQuery({
    queryKey: ["user"],
    queryFn: checkUser,
  });

  return { saleData, userData, getUsdt };
};

export { useIDO };
