import { Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./components/login/Login.jsx";
import Ido from "./components/IDO/Ido.jsx";

function App() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/ido" element={<Ido />} />
    </Routes>
  );
}

export default App;
