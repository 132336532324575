import { useNavigate } from "react-router-dom";
import { Web3 } from "web3";

const Template = ({ Component }) => {
  const socialMediaLinks = [
    { name: "telegram", img: "../../img/telegram-logo.png", link: "https://t.me/rapidrush" },
    { name: "twitter", img: "../../img/twitter-logo.png", link: "https://twitter.com/RapidRushXYZ" },
    { name: "email", img: "../../img/email-logo.png", link: "mailto:info@rapidrush.xyz" },
    { name: "youtube", img: "../../img/youtube-logo.png", link: "https://www.youtube.com/@RapidRushXYZ" },
  ];

  const walletAddress = localStorage.getItem("walletAddress");
  const navigate = useNavigate();
  const web3 = new Web3(window.ethereum);

  const disconnect = async () => {
    await window.ethereum.request({
      method: "eth_requestAccounts",
      params: [{ eth_accounts: {} }],
    });
    localStorage.removeItem("walletAddress");
    const baseUrl = process.env.REACT_APP_BASE_URL || window.location.origin;
    window.location.href = `${baseUrl}/login`;
  };

  return (
    <div className="flex">
      <div className="w-2/5 relative h-screen">
        <span className="fixed bg-[#D9D9D9] -rotate-[39deg] sm:-rotate-[15deg] h-[150vh] w-[150vw] sm:w-2/5 top-10 sm:-top-20 -left-28"></span>
        <img
          src="/img/girl_model.png"
          alt=""
          className="fixed top-[22rem] sm:top-16 -left-[22rem] sm:-left-60 z-10 scale-125 sm:scale-100 max-w-max sm:max-w-full opacity-20 sm:opacity-100"
        />
        
        <a
          href="https://rapidrush.xyz"
          className="absolute top-4 left-4 w-0 h-0 border-t-[50px] border-t-transparent border-r-[75px] border-r-[#A9BCFF] border-b-[50px] border-b-transparent flex items-center cursor-pointer z-10 hover:scale-110 hover:duration-200"
        >
          <div>
            <p className="font-bold pl-7">Back</p>
          </div>
        </a>
      </div>
      <div className="w-3/5 z-10">
        {walletAddress && (
          <div className="absolute top-10 right-10">
            <span></span>
            <button
              className="py-2 px-4 border-2 rounded-lg font-semibold bg-[#d9d9d9] hover:border-[#a9bcff]"
              onClick={disconnect}
            >
              Sign Out
            </button>
          </div>
        )}
        <div className="flex gap-3 absolute bottom-10 right-10">
          {socialMediaLinks.map((link, index) => (
            <a key={index} href={link.link} target="_blank" rel="noopener noreferrer">
              <img src={link.img} alt={link.name} className="h-5 w-5 cursor-pointer" />
            </a>
          ))}
        </div>
        <div className="absolute top-1/2 left-0 sm:left-1/2 w-full sm:w-[unset] p-0 -translate-y-1/2 2xl:translate-x-1/2">
          <div className="max-w-fit m-auto scale-90 2xl:scale-125 mt-10">
            <div className="text-[#FF0000] font-bold text-5xl sm:text-6xl">
              Get! &nbsp; Set! &nbsp; Go!
            </div>
            {Component}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Template;
